<template>
  <z-badge :class="['badge', `${type}`]">
    {{ statusName }}
  </z-badge>
</template>

<script>
import ZBadge from "@zubut/common/src/components/ZBadge";

export default {
  name: "StatusBadge",

  components: {
    ZBadge
  },

  props: {
    statusName: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: true
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../styles/variables/colors.scss";

.badge {
  border-radius: 6px;
  font-size: 0.85rem;
  font-weight: 500 !important;
  padding: 8px 6px !important;

  &.green {
    background-color: $panache;
    color: $free-speech-aquamarine;
  }

  &.red {
    background-color: $tutu;
    color: $guardsman-red;
  }

  &.blue {
    background-color: $alice-blue;
    color: $tory-blue;
  }

  &.yellow {
    background-color: rgba($sunglow, 0.1);
    color: $chocolate;
  }

  &.yellow-light {
    background-color: $forget-me-not;
    color: $chocolate;
  }
}
</style>
