<template>
  <div id="reservations-detail">
    <z-responsive-modal
      modal-id="reservations-detail-modal"
      :show.sync="showModal"
      size="xl"
      :hide-close="showReservationDetail"
      :body-class="
        `reservations-detail-modal-body ${
          showReservationDetail ? 'behind' : ''
        }`
      "
      @hide="handleClose"
    >
      <go-back-title
        class="mb-4 return-mobile"
        :title="title"
        with-action
        @back-action="$router.go(-1)"
      />
      <loading-spinner v-if="loading" class="mt-5" />
      <div v-else class="m-1">
        <div class="reservations-detail-container">
          <reservations-detail-header
            :type-avatar="type"
            :service="service"
            :driver-id="driverId"
          />
        </div>
      </div>
      <div
        class="reservations-detail-cards d-md-inline-flex justify-content-between w-100"
      >
        <div class="reservation-detail-desktop" />
        <reservations-detail-card
          :service="service"
          class="reservations-flex ml-0 h-100"
        />
        <z-tabs
          pills
          class="reservation-detail-tabs  reservations-flex border rounded "
        >
          <z-tab title="Mapa">
            <reservations-detail-map
              :origin="originPoint"
              :destination="positionPoint"
            />
          </z-tab>
          <z-tab lazy class="pb-3 " title="Registros">
            <reservations-logs :service="service" />
          </z-tab>
        </z-tabs>
      </div>
    </z-responsive-modal>
  </div>
</template>

<script>
import ReservationsDetailHeader from "./ReservationsDetailHeader";
import ReservationsDetailCard from "./ReservationsDetailCard";
import ReservationsDetailMap from "./ReservationsDetailMap";
import ReservationsLogs from "./ReservationsLogs";
import ZResponsiveModal from "@zubut/common/src/components/ZResponsiveModal";
import ZTabs from "@zubut/common/src/components/ZTabs";
import GoBackTitle from "@zubut/common/src/components/GoBackTitle";
import ZTab from "@zubut/common/src/components/ZTab";
import LoadingSpinner from "@zubut/common/src/components/LoadingSpinner";
import ServiceType from "@zubut/common/src/constants/services/type";
import Services from "@/services/services";
import { makeShortUUID } from "@/utils/strings";
import Http from "@zubut/common/src/constants/http";

export default {
  name: "ReservationsDetail",

  components: {
    ReservationsDetailHeader,
    ReservationsDetailCard,
    ReservationsDetailMap,
    ReservationsLogs,
    ZResponsiveModal,
    ZTabs,
    ZTab,
    GoBackTitle,
    LoadingSpinner
  },

  props: {
    serviceType: {
      type: String,
      default: "reservation",
      validator: value => {
        return ["delivery", "reservation"].indexOf(value) !== -1;
      }
    }
  },

  data() {
    return {
      showModal: true,
      showReservationDetail: false,
      selectedParcel: "",
      service: { destinations: [{ position: null }, { position: null }] },
      loading: true
    };
  },

  computed: {
    reservationId() {
      return this.$route.params.id;
    },

    driverId() {
      return this.$route.params.driverId;
    },

    type() {
      switch (this.serviceType) {
        case "delivery":
          return ServiceType.NUM_PACKAGE_DELIVERY;
        case "reservation":
          return ServiceType.NUM_RENT_PER_HOUR;
        default:
          return ServiceType.NUM_RENT_PER_HOUR;
      }
    },

    hasDestination() {
      return this.service.destinations && this.service.destinations.lenght > 0;
    },

    originPoint() {
      return this.service.destinations[1]?.position || null;
    },

    positionPoint() {
      return this.service.destinations[0]?.position || null;
    },
    title() {
      if (this.service && this.service.id) {
        return `Detalle del servicio ${makeShortUUID(this.reservationId)}`;
      }
      return "Detalle del servicio";
    }
  },

  created() {
    this.getService();
  },

  methods: {
    getService() {
      this.loading = true;
      Services.getDetails(this.reservationId)
        .then(res => {
          this.service = res.service;
          this.loading = false;
        })
        .catch(err => {
          if (err.statusCode === Http.NOT_FOUND) {
            this.$store.commit("setDisplayNotFound", true);
          } else {
            this.$captureError(err);
            this.loading = false;
          }
        });
    },
    handleClose() {
      this.$router.go(-1);
      this.showModal = false;
    }
  }
};
</script>

<style lang="scss">
#reservations-detail,
#reservations-detail-modal {
  .reservations-detail-modal-body {
    min-height: 94vh;
    padding: 1.5rem 1.5rem 3rem 1.5rem !important;
    transition: background-color 300ms ease;
  }

  .tab-content {
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .reservations-detail-container {
    width: 100%;
  }

  .reservations-detail-cards {
    margin-top: 32px;
    padding-left: 46px;
  }

  .reservation-detail-desktop {
    width: 0;
  }

  .reservation-detail-tabs {
    padding: 24px 24px 0 24px;
    margin-left: 24px;
  }

  @include media-breakpoint-down(lg) {
    .reservation-detail-tabs {
      margin-left: 0 !important;
      margin-top: 24px;
    }

    .reservations-detail-cards {
      padding-left: 0;
    }
  }

  @include media-breakpoint-up(lg) {
    .reservations-flex {
      width: 50%;
    }
    .return-mobile {
      display: none;
    }
  }
}
</style>
