<template>
  <div class="reservations-detail-header">
    <div class="header-flex d-flex justify-content-between align-items-start ">
      <div class="d-flex">
        <service-type-avatar
          :type="typeAvatar"
          size="lg"
          class="reservations-detail-avatar mr-3"
        />
        <div>
          <div data-testid="clientName" class="font-weight-bold header-title">
            {{ name }}
          </div>
          <div data-testid="schedule" class=" text-lg header-schedule ">
            {{ `Horario ${startHour} a ${endHour}` }}
          </div>
          <status-service-reservation
            :status="service.status"
            :driver-id="driverId"
            :start="service.reservationBlock.start"
          />
        </div>
      </div>

      <div class="d-flex header-buttons">
        <z-button
          class="header-button"
          variant="secondary"
          fit="auto"
          @click="print"
        >
          <font-awesome-icon icon="print" size="lg" />
        </z-button>
      </div>
    </div>
  </div>
</template>

<script>
import StatusServiceReservation from "@/app/components/StatusServiceReservation";
import ZButton from "@zubut/common/src/components/ZButton";
import { formatISO } from "@zubut/common/src/utils/time";
import ServiceTypeAvatar from "@zubut/common/src/components/ServiceTypeAvatar";

export default {
  name: "ReservationsDeatailHeader",

  components: {
    StatusServiceReservation,
    ZButton,
    ServiceTypeAvatar
  },

  props: {
    service: {
      type: Object,
      default: () => {
        return {
          client: { name: "" },
          reservationBlock: { start: "", end: "" },
          status: null
        };
      }
    },
    driverId: {
      type: String,
      default: ""
    },
    typeAvatar: {
      type: Number,
      default: 1
    }
  },

  computed: {
    startHour() {
      return this.service.reservationBlock.start
        ? formatISO(this.service.reservationBlock.start, "KK':'mm aaaa ")
        : null;
    },
    endHour() {
      return this.service.reservationBlock.end
        ? formatISO(this.service.reservationBlock.end, "KK':'mm aaaa ")
        : null;
    },
    name() {
      return this.service.client ? this.service.client.name : null;
    }
  },

  methods: {
    print() {
      setTimeout(() => {
        window.print();
      }, 100);
    }
  }
};
</script>

<style scoped lang="scss">
.reservations-detail-header {
  .header-title {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 6px;
  }

  @include media-breakpoint-down(lg) {
    .header-flex {
      flex-direction: column;
    }
    .header-buttons {
      margin-top: 24px;
      flex-direction: column;
      margin-bottom: 6px;
      width: 100%;
    }
    .header-button {
      margin-bottom: 10px;
    }

    .share-button {
      margin-left: 0 !important;
    }
  }

  .header-schedule {
    font-size: 16px;
    margin-bottom: 16px;
  }
}
</style>
