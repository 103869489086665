<template>
  <div class="reservations-detail-logs mt-4 p-2">
    <div class="m-3">
      <p class="font-weight-bold">Registros de viaje</p>

      <div>
        <b-list-group flush class="logs-listing">
          <b-list-group-item
            v-if="createdAt"
            class="pl-0 pr-0 d-flex justify-content-between"
          >
            Servicio requerido y creado con éxito
            <div>{{ formatDate(createdAt) }}</div>
          </b-list-group-item>
          <b-list-group-item
            v-if="checkIn"
            class="pl-0 pr-0 d-flex justify-content-between"
          >
            Check-in
            <div>{{ formatDate(checkIn) }}</div>
          </b-list-group-item>
          <b-list-group-item
            v-if="checkPoint"
            class="pl-0 pr-0 d-flex justify-content-between"
          >
            El mensajero llegó a su destino
            <div>{{ formatDate(checkPoint) }}</div>
          </b-list-group-item>
          <b-list-group-item
            v-if="checkOut"
            class="pl-0 pr-0 d-flex justify-content-between"
          >
            Check-Out
            <div>{{ formatDate(checkOut) }}</div>
          </b-list-group-item>
        </b-list-group>
      </div>

      <div v-if="hasIncidents">
        <hr class="m-0" />
        <b-list-group
          v-for="(incidences, index) in service.incidences"
          :key="`incidences-${index}`"
          flush
          class="logs-listing"
        >
          <b-list-group-item class="pl-0 pr-0 d-flex justify-content-between">
            <div
              class="d-flex align-items-center"
              :title="
                getIncidenceMessage(
                  incidences.categoryId,
                  incidences.incidenceId
                )
              "
            >
              <z-icon name="Alert" size="20" />
              <div class="ml-2">Incidencia</div>
            </div>
            <div>{{ formatDate(incidences.createdAt) }}</div>
          </b-list-group-item>
        </b-list-group>
      </div>
    </div>
  </div>
</template>

<script>
import { formatISO } from "@zubut/common/src/utils/time";
import { incidenceMessage } from "@zubut/common/src/utils/incidences";
import { imageDownload } from "@zubut/common/src/utils/download";
import Logs from "@zubut/common/src/constants/parcels/logs";
import ZIcon from "@zubut/common/src/components/ZIcon";

export default {
  name: "ReservationsDetailLogs",
  components: {
    ZIcon
  },

  props: {
    service: {
      type: Object,
      default: () => {}
    }
  },

  data() {
    return {
      loading: false
    };
  },

  computed: {
    createdAt() {
      return this.service?.createdAt;
    },
    checkIn() {
      return this.service?.resume?.checkIn;
    },
    checkPoint() {
      if (this.service.resume.checkPoints)
        return this.service?.resume?.checkPoints[0]?.arrived;
      else return false;
    },
    checkOut() {
      return this.service?.resume?.checkOut;
    },
    hasIncidents() {
      return this.service?.incidences?.length > 0;
    }
  },

  methods: {
    formatDate(date) {
      return formatISO(date, "dd 'de' LLL'. de' yyyy KK':'mm aaaa");
    },

    getEvent(log) {
      return Logs.get[log];
    },
    getIncidenceMessage(categoryId, incidenceId) {
      return incidenceMessage(categoryId, incidenceId);
    },

    downloadSignature(signature) {
      imageDownload(signature, `parcel-signature.jpg`);
    }
  }
};
</script>

<style scoped lang="scss">
.logs-listing {
  font-size: 13px;
}
</style>
