<template>
  <div class="reservations-detail-map">
    <p class="map-title font-weight-bold text-xl">
      Mapa
    </p>
    <z-map id="reservations-map" class="reservation-detail-map rounded"
      ><div v-if="hasPosition">
        <l-marker :lat-lng="position" :icon="logIcon">
          <l-popup class="reservations-popup ">Origen</l-popup>
        </l-marker>
      </div>
      <div v-if="hasOrigin">
        <l-marker :lat-lng="origin" :icon="logIcon" />
        <l-marker :lat-lng="origin" :icon="pinStoreIcon">
          <l-popup class="reservations-popup ">Origen</l-popup>
        </l-marker>
      </div>
      <div v-if="hasDestination">
        <l-marker :lat-lng="destination" :icon="logIconSelect" />
        <l-marker :lat-lng="destination" :icon="pinHouseIcon">
          <l-popup class="reservations-popup ">Destino</l-popup>
        </l-marker>
      </div>
    </z-map>
  </div>
</template>

<script>
import ZMap from "@zubut/common/src/components/ZMap";
import MapMixin from "@zubut/common/src/mixins/map";
import { LMarker, LPopup } from "vue2-leaflet";

export default {
  name: "ReservationsDetailMap",

  components: {
    ZMap,
    LMarker,
    LPopup
  },
  mixins: [MapMixin],

  props: {
    position: {
      type: Object,
      default: () => {
        return null;
      }
    },
    origin: {
      type: Object,
      default: () => {
        return null;
      }
    },
    destination: {
      type: Object,
      default: () => {
        return null;
      }
    }
  },

  data() {
    return {
      logIcon: this.makeIcon(require("@/assets/img/ic_log.png"), {
        size: [18, 18],
        anchor: [9, 9],
        popupAnchor: [0, -9]
      }),
      logIconSelect: this.makeIcon(require("@/assets/img/ic_log_select.png"), {
        size: [18, 18],
        anchor: [9, 9],
        popupAnchor: [0, -9]
      }),
      pinStoreIcon: this.makeIcon(require("@/assets/img/pin-store.png"), {
        size: [34, 40],
        anchor: [17, 40],
        popupAnchor: [0, -40]
      }),
      pinHouseIcon: this.makeIcon(require("@/assets/img/pin-house.png"), {
        size: [34, 40],
        anchor: [17, 40],
        popupAnchor: [0, -40]
      }),
      loading: true
    };
  },

  computed: {
    hasOrigin() {
      return this.origin ? true : false;
    },
    hasPosition() {
      return this.position ? true : false;
    },
    hasDestination() {
      return this.destination ? true : false;
    }
  }
};
</script>

<style lang="scss" scoped>
.reservation-detail-map {
  overflow: hidden;
  width: 100%;
  height: 450px;
}
.map-title {
  margin-top: 24px;
}
</style>
