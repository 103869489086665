<template>
  <div class="reservations-detail-card border rounded ml-0">
    <div class="reservation-detail-card-header d-flex ">
      <div class="id-text m-4 pl-2 w-50">
        <p class="font-weight-bold text-lg header-title mb-1">ID Orden</p>
        <p class="header-text mb-0">{{ shortID }}</p>
      </div>
    </div>
    <div class="reservations-detail-card-table p-4">
      <div class="border-bottom d-flex justify-content-between">
        <p class="font-weight-bold">Ciudad</p>
        <p class="text-right">{{ cityName }}</p>
      </div>
      <div class="border-bottom d-flex justify-content-between mt-3">
        <p class="font-weight-bold">Horario reservado</p>
        <p class="text-right">{{ reservedHours }}</p>
      </div>
      <div
        v-if="acceptedAt"
        class="border-bottom d-flex justify-content-between mt-3"
      >
        <p class="font-weight-bold">Check In</p>
        <p>{{ checkIn }}</p>
      </div>
      <div
        v-if="finishedAt"
        class="border-bottom d-flex justify-content-between mt-3"
      >
        <p class="font-weight-bold">Check Out</p>
        <p class="text-right">{{ checkOut }}</p>
      </div>
      <div class="border-bottom d-flex justify-content-between mt-3">
        <p class="font-weight-bold">Fecha de Solicitud</p>
        <p class="text-right">{{ createdDate }}</p>
      </div>
      <div
        v-if="service.additionalHoursRequest"
        class="border-bottom d-flex justify-content-between mt-3"
      >
        <p class="font-weight-bold">Tiempo Extra</p>
        <p class="text-right">{{ `${service.additionalHoursRequest} hrs` }}</p>
      </div>
      <div class="border-bottom d-flex justify-content-between mt-3">
        <p class="font-weight-bold">Mensajero Asignado</p>
        <p class="text-right">{{ name }}</p>
      </div>
      <div v-if="service.destinations">
        <address-card
          v-for="(destination, index) in service.destinations"
          :key="index"
          :address="destination"
          :address-type="destination.order === 0 ? 'origin' : 'destination'"
        />
      </div>
    </div>
  </div>
</template>

<script>
import AddressCard from "@zubut/common/src/components/AddressCard";
import { formatISO, diffInHours } from "@zubut/common/src/utils/time";
import { makeShortUUID } from "@zubut/common/src/utils/strings";

export default {
  name: "ReservationsDetailCard",

  components: {
    AddressCard
  },

  props: {
    service: {
      type: Object,
      default: () => {
        return {
          courier: {
            name: "Nombre"
          },
          reservationBlock: { start: "", end: "" }
        };
      }
    }
  },

  computed: {
    createdDate() {
      return this.service.createdAt
        ? formatISO(this.service.createdAt, "iiii, d 'de' LLLL, Y H':'mm 'hrs'")
        : null;
    },

    reservedHours() {
      return this.service.reservationBlock
        ? diffInHours(
            this.service.reservationBlock.start,
            this.service.reservationBlock.end
          ) + " hrs"
        : null;
    },

    checkIn() {
      return this.service.resume
        ? formatISO(
            this.service.resume.acceptedAt,
            "iiii, d 'de' LLLL, Y H':'mm 'hrs'"
          )
        : null;
    },

    checkOut() {
      return this.service.resume
        ? formatISO(
            this.service.resume.finishedAt,
            "iiii, d 'de' LLLL, Y H':'mm 'hrs'"
          )
        : null;
    },

    shortID() {
      return makeShortUUID(this.service.id);
    },

    acceptedAt() {
      return this.service.resume && this.service.resume.acceptedAt
        ? this.service.resume.acceptedAt
        : null;
    },

    finishedAt() {
      return this.service.resume && this.service.resume.finishedAt
        ? this.service.resume.finishedAt
        : null;
    },

    name() {
      return this.service.courier ? this.service.courier.name : "";
    },

    cityName() {
      return this.service.city?.name;
    }
  }
};
</script>

<style lang="scss" scoped>
.reservations-detail-card {
  border-radius: 20px;
  overflow: hidden;
  .reservation-detail-card-header {
    background-color: $solitude;
  }

  .header-title {
    font-size: 12px;
  }

  .header-text {
    font-size: 16px;
  }

  .reservations-detail-card-table {
    font-size: 12px;
  }
}
</style>
