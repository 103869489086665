const SCHEDULED = "Agendado";
const WITHOUT_DRIVER = "Sin mensajero";
const LATE = "Atrasado";
const IN_ROUTE = "En ruta";

const NUM_SCHEDULED = 0;
const NUM_WITHOUT_DRIVER = 1;
const NUM_LATE = 2;
const NUM_IN_ROUTE = 3;

const get = {};
get[NUM_SCHEDULED] = SCHEDULED;
get[NUM_WITHOUT_DRIVER] = WITHOUT_DRIVER;
get[NUM_LATE] = LATE;
get[NUM_IN_ROUTE] = IN_ROUTE;

const options = Object.keys(get).map(key => ({
  text: get[key],
  value: parseInt(key)
}));

export default {
  get,
  options,
  SCHEDULED,
  WITHOUT_DRIVER,
  LATE,
  IN_ROUTE,
  NUM_SCHEDULED,
  NUM_WITHOUT_DRIVER,
  NUM_LATE,
  NUM_IN_ROUTE
};
